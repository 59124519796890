export default [
  {
    header: 'admin.navigation.others',
  },
  {
    title: 'admin.navigation.messages',
    route: 'messages-index',
    icon: 'MailIcon',
    action: 'index',
    resource: 'messages',
  },
  {
    title: 'admin.navigation.extensions',
    route: 'extensions-index',
    icon: 'ExternalLinkIcon',
    action: 'index',
    resource: 'extensions',
  },
  {
    title: 'admin.navigation.navigations',
    route: 'navigations-index',
    icon: 'Navigation2Icon',
    action: 'index',
    resource: 'navigations',
  },
]
