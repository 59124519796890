<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.orders.new"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Замовлення
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.orders.new }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="loaded"
      :settings="{ maxScrollbarLength: 60, wheelPropagation: false }"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link :to="'/orders/'">
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notifications.orders.new }}
            </span>
          </p>
          <small class="notification-text text-primary">нові</small>
        </b-media>
      </b-link>
      <b-link :to="'/orders/'">
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notifications.orders.paid }}
            </span>
          </p>
          <small class="notification-text text-info">оплачено</small>
        </b-media>
      </b-link>
      <b-link :to="'/orders/'">
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notifications.orders.notpaid }}
            </span>
          </p>
          <small class="notification-text text-danger">неоплачено</small>
        </b-media>
      </b-link>
      <b-link :to="'/orders/'">
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notifications.orders.inwork }}
            </span>
          </p>
          <small class="notification-text text-warning">в обробці</small>
        </b-media>
      </b-link>
      <b-link :to="'/orders/'">
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notifications.orders.delivering }}
            </span>
          </p>
          <small class="notification-text text-warning">доставляється</small>
        </b-media>
      </b-link>
      <b-link :to="'/orders/'">
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notifications.orders.completed }}
            </span>
          </p>
          <small class="notification-text text-success">завершено</small>
        </b-media>
      </b-link>
      <b-link :to="'/orders/'">
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notifications.orders.cancel }}
            </span>
          </p>
          <small class="notification-text text-danger">відміна</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="fetch"
    >Оновити</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BButton,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      notifications: {
        orders: {
          new: 0,
        },
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loaded = false
      this.$http.get('/api/profile').then(res => {
        const { notifications } = res.data.data
        this.notifications = notifications
        this.loaded = true
      })
    },
  },
}
</script>

<style>

</style>
