export default [
  {
    header: 'admin.navigation.products',
  },
  {
    title: 'admin.navigation.catalog',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'admin.catalog.title',
        route: { name: 'pages-catalog-update' },
        action: 'update',
        resource: 'pages',
      },
      // {
      //   title: 'admin.navigation.sale',
      //   route: { name: 'pages-sale-update' },
      //   action: 'update',
      //   resource: 'pages',
      // },
      // {
      //   title: 'admin.navigation.novelty',
      //   route: { name: 'pages-novelty-update' },
      //   action: 'update',
      //   resource: 'pages',
      // },
      // {
      //   title: 'admin.navigation.coming_soon',
      //   route: { name: 'pages-coming_soon-update' },
      //   action: 'update',
      //   resource: 'pages',
      // },
      {
        title: 'admin.navigation.categories',
        route: { name: 'product-categories-index' },
        action: 'index',
        resource: 'categories',
      },
      {
        title: 'admin.navigation.products',
        route: 'products-index',
        action: 'index',
        resource: 'products',
      },
      {
        title: 'admin.navigation.attributes',
        route: 'attributes-index',
        action: 'index',
        resource: 'attributes',
      },
      {
        title: 'admin.navigation.stickers',
        route: 'stickers-index',
        action: 'index',
        resource: 'stickers',
      },
      {
        title: 'admin.navigation.services',
        route: 'services-index',
        action: 'index',
        resource: 'stickers',
      },
      {
        title: 'admin.navigation.sales',
        route: 'sales-index',
        action: 'index',
        resource: 'sales',
      },
    ],
  },
]
