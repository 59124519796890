export default [
  {
    header: 'admin.navigation.marketing_pages',
  },
  {
    title: 'admin.navigation.orders',
    route: 'orders-index',
    icon: 'DollarSignIcon',
    action: 'index',
    resource: 'orders',
  },
  {
    title: 'admin.navigation.marketing',
    icon: 'PaperclipIcon',
    children: [
      {
        title: 'admin.navigation.feeds',
        route: 'feeds-index',
        action: 'index',
        resource: 'feeds',
      },
      {
        title: 'admin.navigation.coupons',
        route: 'coupons-index',
        action: 'index',
        resource: 'coupons',
      },
      {
        title: 'admin.navigation.reviews',
        route: 'reviews-index',
        icon: 'PenToolIcon',
        action: 'index',
        resource: 'reviews',
      },
    ],
  },
  {
    header: 'admin.navigation.seo_pages',
  },
  {
    title: 'admin.navigation.seo',
    icon: 'AnchorIcon',
    children: [

      {
        title: 'admin.navigation.seo_pages',
        route: 'seo_pages-index',
        action: 'index',
        resource: 'seo_pages',
      },
      {
        title: 'admin.navigation.seo_templates',
        route: 'seo_templates-index',
        action: 'index',
        resource: 'seo',
      },
    ],
  },
]
